<template>
  <div class="SysParam">
    <simple-c-u-r-d
      search-url="/api/sysLog/list"
      :show-form="showForm"
      is-pageable
    />
  </div>
</template>

<script>
import SimpleCURD from '@/components/simple/SimpleCURD'
export default {
  name: 'SysLog',
  components: { SimpleCURD },
  data() {
    return {
      showForm: [
        { param: 'name', name: '操作账号', width: '100px', search: true, clearable: true },
        { param: 'ip', name: '操作IP', width: '100px', search: true, clearable: true },
        {
          param: 'type',
          name: '日志类型',
          width: '100px',
          clearable: true,
          formatter: (row) => {
            switch (row.type) {
            case 1: return (<el-tag type="success" >登录</el-tag>)
            case 2: return (<el-tag type="warning" >登出</el-tag>)
            case 3: return (<el-tag color="#a3cf62" style="color:#6950a1;">新增</el-tag>)
            case 4: return (<el-tag color="#f58220" style="color:#007d65;">删除</el-tag>)
            case 5: return (<el-tag color="#fedcbd" style="color:#145b7d;">修改</el-tag>)
            default:return (<el-tag type="info" >未定义</el-tag>)
            }
          },
          search: true,
          type: 'select',
          option: [{ value: 1, label: '登录' }, { value: 2, label: '登出' }, { value: 3, label: '新增' }, { value: 4, label: '删除' }, { value: 5, label: '修改' }],
        },
        { param: 'content', name: '日志内容', search: true, clearable: true },
        { param: 'createTime', name: '创建时间', width: '180px', type: 'date', search: true, clearable: true },
      ],
    }
  }
}
</script>

<style scoped>

</style>
